<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-input type="text" placeholder="请输入动态标题" v-model="query.newsTitle"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入投诉人名称" v-model="query.nickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.complaintSource" placeholder="请选择投诉来源" clearable>
            <el-option v-for="item in searchForm.complaintSourceList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onAdd" v-if="permission.saveComplaintTypeInfo">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'complaintTypeId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column prop="complaintTypeId" label="编号" align="center" width="50">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="nickName" label="投诉用户名称" min-width="100" align="center">
        </el-table-column>
        <el-table-column label="用户头像" width="70" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb" style="max-width: 40px; max-height: 40px" :src="scope.row.headImg" :preview-src-list="[scope.row.headImg]" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="loginName" label="手机号" width="100" align="center"></el-table-column>
        <el-table-column prop="newsTitle" label="动态标题" min-width="120" align="center"></el-table-column>
        <el-table-column prop="complaintSource" label="投诉来源" align="center" width="70">
          <template slot-scope="scope"> {{ scope.row.complaintSource | getStateName(searchForm.complaintSourceList) }}</template>
        </el-table-column>
        <el-table-column prop="complaintTypeName" label="投诉类型" align="center" width="70"></el-table-column>
        <el-table-column prop="isShow" label="是否处理" align="center" width="70px">
          <template slot-scope="scope">
            <i :class="[scope.row.isDeal == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column label="投诉内容" width="70" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 1)">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="投诉图集" width="70" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 2)">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="处理备注" width="70" align="center">
          <template slot-scope="scope">
            <span @click="showContenHandle(scope.row, 3)" v-if="scope.row.dealRemarks.length">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="处理时间" align="center" width="135">
          <template slot-scope="scope">
            {{ scope.row.dealTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="投诉时间" align="center" width="135">
          <template slot-scope="scope">
            {{ scope.row.addTime | formatDate }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="60px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="处理" placement="top" v-if="permission.updateNewsComplaintInfo && scope.row.isDeal==0">
              <el-popconfirm title="您确定已处理么？" icon-color="red" @confirm="clickDealInfo(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-s-check" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 查看动态投诉类型详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <!-- 添加动态投诉类型 -->
    <el-dialog title="投诉处理" :visible.sync="dealDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="140px" ref="dealForm" :model="dealForm" :rules="addRules">
        <el-form-item label="处理备注" prop="dealRemarks">
          <el-input type="textarea" :rows="4" size="medium" v-model="dealForm.dealRemarks"  placeholder="请输入处理备注"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="dealBtnLoading" @click="clickDealConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 投诉信息 -->
    <el-dialog title="动态投诉图集" :visible.sync="dialogLookGalleryVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <ComplaintGalleryList :complaintId="lookGalleryModel.complaintId" v-if="dialogLookGalleryVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import circleManager from '@/request/circleManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import ComplaintGalleryList from './ComplaintGalleryList.vue'
export default {
  mixins: [getList],
  components: {
    ShowContent,
    ComplaintGalleryList,
  },
  data() {
    return {
      requst: {
        module: circleManager,
        url: 'newsComplaintInfoList',
        addUrl: '',
        updateUrl: '',
      },
      dialogSeeContent: false,
      rowInfo: {},
      query: {
        newsTitle: '', //搜索词
        nickName: '',
        complaintSource: '',
      },
      searchForm: {
        complaintSourceList: [
          {
            value: 1,
            label: '动态',
          },
          {
            value: 2,
            label: '评论',
          },
        ],
      },
      dialogLookGalleryVisible: false,
      lookGalleryModel: {
        complaintId: 0,
      },

      //
      dealDialog: false,
      dealBtnLoading: false,
      dealForm: {
        complaintId: 0,
        dealRemarks: '',
        isDeal: 1,
      },
      addRules: {
        dealRemarks: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ],
      },
    }
  },
  created() {},
  methods: {
    showContenHandle(row, type) {
      switch (type) {
        case 1:
          this.rowInfo.title = '投诉内容'
          this.rowInfo.content = row.complaintContent
          this.dialogSeeContent = true
          break
        case 2:
          this.lookGalleryModel.complaintId = row.complaintId
          this.dialogLookGalleryVisible = true
          break
        case 3:
          this.rowInfo.title = '处理备注'
          this.rowInfo.content = row.dealRemarks
          this.dialogSeeContent = true
          break
        default:
          break
      }
    },

    // 点击添加
    onAdd() {
      this.dealDialog = true
    },

    // 点击添加---取消
    clickAddOrEditCancel() {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickDealConfirm() {
      this.$refs.dealForm.validate(async (valid) => {
        if (valid) {
          try {
            this.dealBtnLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.dealForm))
            let data = await circleManager.updateNewsComplaintInfo(dataValue)
            if (data.code == 200) {
              this.$message({
                message: data.message,
                type: 'success',
              })
              this.refresCurrentPagehData()
              this.resetAddOrEditData()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.dealBtnLoading = false
          }
        }
      })
    },

    // 清除添加数据
    resetAddOrEditData() {
      this.dealDialog = false
      this.dealForm.complaintId = 0
      this.dealRemarks = ''
      this.isDeal = 1
    },
    // 点击编辑信息
    async clickDealInfo(row) {
      this.dealForm.complaintId = row.complaintId
      this.dealDialog = true
    },
  },
}
</script>

<style scoped>
</style>
